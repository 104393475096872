<template>
  <!--我的业务-->
  <div class="my-enlis" @click.stop="hideSearch">
    <div class="ybox-mod enbuss-mod">
      <div class="ybox-title">
        <card-header :title="headerTitle" :edit='$attrs.edit' @deleteData="deleteData" class='ybox-title'>
          <template #left  >
            <div class="pull-left">
              <en-icon v-if="flag" name="fanhui" size="small" style="vertical-align:middle;cursor: pointer;margin-left: 0px;" @click.native="callback"> </en-icon>
              <span >我的业务</span>
          </div>
          </template>
          <template #right >
            <div class="pull-right">
              <!-- 搜索下拉框 -->
              <viewSearch v-if="type === 'itself'&&!$attrs.edit" :show="show" @toData="getData"></viewSearch>
              <en-icon v-if="type === 'index'" name="zuocecaidanlan-shouqi" size="20" style="color: #A9B5C6;cursor: pointer;margin-left: 10px;" @click.native="goDetails('/myBuss')"></en-icon>
            </div>
          </template>
        </card-header>
      </div>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="mybuss-enlis">
          <div v-for="(item, k) in noticeList" :key="k" class="mod" @click="modClick(item, k)">
            <div class="col col-icon">
              <p>
                <span class="iconfont" :style="iconColor(k)">
                  <en-icon :name="getBusIcon(item.icon).icon" size="small" class="icon-color"></en-icon>
                </span>
              </p>
            </div>
            <div class="pull-left">
              <div class="col col-num">
                <p>{{ item.code }}</p>
              </div>
              <div class="col col-tit">
                <p>{{ item["templateName"] || item["name"] || "" }}</p>
              </div>
            </div>
            <div class="pull-right">
              <div class="col col-time">
                <p>{{ getDateFormat(item["createTime"]) }}</p>
              </div>
              <div class="col col-operate">
                <el-tag style="height: 24px;line-height: 24px;border:none;border-radius:2px;" :style="'color:' + color[item.billStatus][0] + ';' + 'background:' + color[item.billStatus][1] + ';'">
                  {{ item.billStatusName || billStatus[item.billStatus] }}
                </el-tag>
                <!-- <p><span :style="'color:'+color[item.billStatus]">{{ (item.billStatusName || billStatus[item.billStatus]) }}</span><span class="en-name"></span></p> -->
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore('/myBuss')"></viewAddMore>
      </div>
    </div>
    <!-- 悬浮的 关闭多条 回到第一页 -->
    <!-- <div class="colse-back animated bounceInDown hide">
    </div> -->
    <viewBacktop class="back-top" elementId="mybuss-enlis"></viewBacktop>
  </div>
</template>

<script>
import { otherEntranceRoute } from "@/components/businessDetail/helper.js";
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
import CardHeader from "@/views/home/components/card-header";
import viewSearch from "./readComm/viewSearch";
import viewBacktop from "./readComm/viewBacktop";
import viewAddMore from "./readComm/viewAddMore";

export default {
  name: "MyBuss",
  components: {
    viewSearch, viewBacktop, viewAddMore, CardHeader
  },
  mixins: [en],
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 10, // 每页的 数量
      color: {
        "000": ["rgba(70, 148, 223, 1)", "rgba(70,148,223,0.1)"], // 蓝色
        "001": ["rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"], // 黄色
        "002": ["rgba(255, 173, 44, 1)", "rgba(70,148,223,0.1)"], // 蓝色
        "006": ["rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"], // 绿色
        "004": ["rgba(247, 107, 107, 1)", "rgba(247, 107, 107, 0.1)"], // 红色
        "003": ["rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"], // 灰色
        "007": ["rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"], // 灰色
        "008": ["rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"]// 绿色
      }, // 第一项是 字体颜色 第二项是 背景颜色
      billStatus: {
        "000": "未提交",
        "001": "待处理",
        "002": "审批中",
        "006": "已审批",
        "004": "已终止",
        "003": "已结束",
        "007": "已作废",
        "008": "已提交"
      },
      options: [
        { id: "", name: "请选择" },
        { id: "code", name: "单据编号" },
        { id: "templateName", name: "模板名称" },
        { id: "billStatusName", name: "单据状态" },
        { id: "createTime", name: "创建时间" }
      ],
      // 是否显示无数据
      noData: false,
      // 查询条件 加载更多时用到
      params: {},
      show: true
    };
  },
  mounted() {
    this.handle();
    this.getList();
  },
  computed: {
    headerTitle() {
      return "我的业务";
    }
  },
  methods: {
    getBusIcon(icon) {
      if (icon <= 20) {
        return { icon: `bus-board-icon${icon}` };
      }
      return { icon: `bus-ywmb-icon${icon}` };
    },
    deleteData() {
      this.$emit("deleteData");
    },
    // 查询 我的业务数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }
      let p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo
      };
      if (JSON.stringify(this.params) !== "{}") {
        p = Object.assign(p, this.params);
      }
      if (this.pageNo === 1) {
        this.noticeList = [];
      }
      let rspData;
      try {
        rspData = await enService.queryMyMdBusinessList(p);
      } catch (error) {
        rspData = { records: [] };
      }
      this.myNotice = rspData || { records: [] };
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      this.noData = this.noticeList.length === 0;
      this.loading = false;
    },
    modClick(item, index) {
      console.log("输出点击项", item);
      const pageSwitchData = {
        pageNo: this.pageNo, // 列表请求的当前页
        pageSize: this.pageSize, // size
        selectIndex: index, // 选中的第几条数据index
        interfaceName: "queryMyMdBusinessList", // 请求的接口名称
        params: {
          pageSize: this.pageSize,
          pageNo: this.pageNo
        }, // 该接口所需的参数
        idKey: "id", // 数据id对应的字段
        businessTypeKey: "type", // 业务类型对应的字段
        loadedData: this.noticeList // 已加载的数据
      };
      this.$router.push(
        otherEntranceRoute({
          id: item.id,
          businessType: item.type,
          templateId: item.templateId,
          templateName: item.templateName,
          pageSwitchData
        })
      );
    },
    // 处理图标颜色
    iconColor(k) {
      const obj = {
        0: "background: #4593DF;",
        1: "background: #25C393;",
        2: "background: #FFAD2B;",
        3: "background: #F76B6B;",
        4: "background: #F7BF27;",
        5: "background: #F76B6B;"
      };
      const a = k % 6;
      return obj[a];
    },
    // 获取搜索数据
    getData(data) {
      // 混入方法
      this.search(data);
    }
  }
};
</script>

<style lang="scss" scoped>
// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head{
      padding:5px 20px;
      // /deep/.right{
      //   min-width: 766px !important;
      // }
    }
    // 标题
    .ybox-title {
      .pull-left {
        float: left !important;
        font-size: 14px;
        line-height: 40px;
        // font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
      }
      .pull-right {
        float: right !important;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);
      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        padding-left: 10px;
        padding-right: 10px;
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }
        .mod {
          padding: 14px 2% 0;
          padding-left: 70px;
          line-height: 24px;
          color: #606060;
          cursor: pointer;
          zoom: 1;
          background: rgba(232, 236, 242, 0.25);
          border-radius: 4px;
          margin-bottom: 8px;
          position: relative;
          // min-height: 72px;
          min-height: 80px;
          // border-bottom: 1px solid #f3f3f3;
          .col {
            min-height: 24px;
            p {
              display: inline-block;
              margin: 0;
            }
          }
          // 图标
          .col-icon {
            min-height: 24px;
            position: absolute;
            left: 20px;
            top: 15px;
            width: 40px;
            .iconfont {
              width: 40px;
              height: 40px;
              color: #fff;
              border-radius: 50%;
              line-height: 40px;
              text-align: center;
              display: block;
              margin: 0;
              font-size: 22px;
              font-style: normal;
            }
          }
          // 左侧
          .pull-left {
            float: left !important;
            text-align: left;
            width: 70%;
            .col-num {
              display: inline-block;
              vertical-align: top;
            }
            .col-tit {
            }
          }
          // 右侧
          .pull-right {
            float: right !important;
            text-align: right;
            width: 20%;
            .col-time{
              color: #91A1B7;
            }
          }
        }
        .mod:after {
          content: "";
          display: block;
          clear: both;
        }
        .mod:hover {
          // background: #F5F9FD;
          background: rgba(162, 205, 247, 0.25);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
